<template>
    <div>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Aéroport</th>
                    <th scope="col">Piste</th>
                    <th scope="col">Périodicité</th>
                    <th scope="col">Dernière Maintenance</th>
                    <th scope="col">Prochaine Semestrielle</th>
                    <th scope="col">Prochaine Annuelle</th>
                </tr>
            </thead>

            <tbody>
                <tr :key="client.id" v-for="client in clients">
                    <td><strong>{{ client.client }}</strong></td>
                    <td><strong>{{ client.piste }}</strong></td>
                    <td>{{ client.periode }}</td>
                    <td>{{ client.lastdt }} {{ getType(client.type) }}</td>
                    <td :class="clr(client.diffdtsem)">{{ client.nextdtsem }}</td>
                    <td :class="clr(client.diffdtann)">{{ client.nextdtann }}</td>
                </tr>
            </tbody>
        </table>

        <br />
        <br />
        <br />
        <br />
    </div>

</template>

<script>
import store from "../store";
import { getPrefix, executeApi, selectApi } from "../lib/api.js";
import Vue from "vue";
import moment from "moment";

export default {
    name: "PeriodeCGB",
    store: store,
    data() {
        return {
            countclient: 0,
            clients: []
        };
    },
    computed: {
    },
    methods: {
        clr: function (diff) {
            let d = parseInt(diff)
            if (d < 0) return "noir"
            if (d < 15) return "rouge"
            if (d < 30) return "orange"
            return "vert"
        },
        getType: function (type) {
            return type == 0 ? " Semestrielle" : " Annuelle"
        },
        refresh() {
            let id = store.state.piste;
            let sql = ""

            sql =
                "SELECT c.id as idclient,c.nom as nomclient,c.periode,p.id as idpiste,p.nom as nompiste,m.`dt`,m.type FROM cgbcommun.client AS c" +
                " LEFT JOIN piste AS p ON p.idclient=c.Id" +
                " LEFT JOIN maintenance AS m ON m.idpiste=p.id" +
                " WHERE (c.nom<>'') AND (m.type=0 OR m.type=1) AND (c.periode<>0) ORDER BY c.nom ASC,p.nom ASC,m.dt DESC"

            selectApi(this, sql).then(
                (response) => {
                    this.countclient = 0

                    this.clients = []
                    let lastclient = -1
                    let lastpiste = 1

                    response.data.result.forEach(item => {

                        if (lastclient != item.idclient && lastpiste != item.idpiste) {
                            let nbmois = item.periode

                            let lastdt = moment(item.dt)
                            let nextdtsem = moment(item.dt)
                            let nextdtann = moment(item.dt)

                            let diffdtsem = 0
                            let diffdtann = 0
                            let now = moment()

                            if (nbmois == 6) {
                                if (item.type == 0) {   // Semestrielle
                                    nextdtann = moment(item.dt).add(6, 'M')
                                    nextdtsem = moment(item.dt).add(12, 'M')
                                }
                                if (item.type == 1) {   // Annuelle
                                    nextdtsem = moment(item.dt).add(6, 'M')
                                    nextdtann = moment(item.dt).add(12, 'M')
                                }
                                diffdtsem = nextdtsem.diff(now, 'days')
                                diffdtann = nextdtann.diff(now, 'days')
                            }

                            if (nbmois == 12) {
                                nextdtsem = ''
                                nextdtann = moment(item.dt).add(12, 'M')

                                diffdtsem = 999
                                diffdtann = nextdtann.diff(now, 'days')
                            }

                            this.countclient++

                            const p = {
                                id: this.countclient,
                                client: item.nomclient,
                                piste: item.nompiste,
                                periode: nbmois,
                                type: item.type,
                                lastdt: lastdt.format("DD/MM/YYYY"),
                                nextdtsem: nextdtsem == '' ? '' : nextdtsem.format("DD/MM/YYYY"),
                                diffdtsem: diffdtsem,
                                nextdtann: nextdtann == '' ? '' : nextdtann.format("DD/MM/YYYY"),
                                diffdtann: diffdtann
                            }

                            this.clients.push(p)
                            lastclient = item.idclient
                            lastpiste = item.idpiste


                        }
                    });
                },
                (response) => {
                }
            )
        }

    },
    mounted() {
        if (store.state.typeUser != 3) {
            this.$router.push({ name: "login" });
        }
        this.refresh();
    },
};
</script>

<style lang="scss">
@import "src/assets/scss/design.scss";

.vert {
    color: green;
    background-color: white;
}

.orange {
    color: white;
    background-color: orange;
}

.rouge {
    color: white;
    background-color: red;
}

.noir {
    color: white;
    background-color: black;
}
</style>